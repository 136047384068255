import React, { useEffect, useRef, useState } from "react";
import Styles from "./home.module.css";
import { RightArrow } from "../../Assets";
import AboutVideo from "../../Assets/video/AboutVideo.mp4";

const About = () => {
  const [stateChange, setStateChange] = useState(false);
  const serviceData = [
    {
      title: "Customer-Centric Excellence",
      description:
        "Our software solutions are designed with a customer-centric approach, ensuring that users truly appreciate the products we create.",
    },
    {
      title: "Outcome-Driven Philosophy",
      description:
        "We firmly believe in delivering tangible results, focusing on the outcomes our software can achieve for your business",
    },
    {
      title: "Quality Without Compromise.",
      description:
        "Our commitment to unwavering quality guarantees that you receive software that meets the highest standards.",
    },
    {
      title: "Affordable Innovation",
      description:
        "We provide cost-effective solutions that blend innovation with affordability, ensuring the best value for your investment.",
    },
  ];

  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });

  useEffect(() => {
    // document.getElementById("vid").play();
  }, [stateChange]);

  return (
    <div
      style={{ background: "none", padding: "5%" }}
      className={Styles.heroBody}
      id="about"
    >
      <span className={`${Styles.aboutTittle} ${Styles.mobileHide}`}>
        What sets{" "}
        <span style={{ color: "#0078D1" }}> us apart in the crowd ? </span>
      </span>
      <video
        className={Styles.aboutVideo}
        ref={videoRef}
        loop
        autoPlay
        muted
        playsInline
      >
        <source src={AboutVideo} type="video/mp4" />
      </video>
      <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
        <span className={`${Styles.aboutTittle} ${Styles.webHide}`}>
          What sets{" "}
          <span style={{ color: "#0078D1" }}> us apart in the crowd ? </span>
        </span>
        {serviceData.map((res, index) => (
          <div className={Styles.aboutService}>
            <spna className={Styles.aboutSubheading}>
              <img src={RightArrow} alt="RightArrow" />
              {res.title}
            </spna>
            <spna className={Styles.aboutpara}>{res.description}</spna>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
