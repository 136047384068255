import { Arrow } from "../../../Assets";
import "./caseStudyDetails.css";

export const SolutionSection = ({
  statement,
  image,
  subStatement,
  solutionsList,
}) => {
  return (
    <div className="caseStudyDetails__bottomContentSectionContainer2">
      <h2
        className="caseStudyDetails__bottomContentSectionHeader"
        style={{
          marginTop: 0,
        }}
      >
        Solution
      </h2>
      {statement && (
        <p
          className="caseStudyDetails__bottomContentSectionDescription"
          style={{
            margin: 0,
            marginBottom: !subStatement ? 30 : 0,
          }}
        >
          {statement}
        </p>
      )}
      {subStatement && (
        <p
          className="caseStudyDetails__bottomContentSectionDescription"
          style={{
            marginTop: 10,
            marginBottom: 30,
          }}
        >
          {subStatement}
        </p>
      )}
      {/* left image */}
      <div className="caseStudyDetails__bottomContentSolutionImage">
        <img
          src={image}
          height={370}
          className="caseStudyDetails__bottomContentSectionImage"
          alt=""
        />

        {/* solution content */}
        <div>
          {solutionsList.map((solution) => (
            <div className="caseStudyDetails__bottomContentSectionList">
              <img src={Arrow} alt="Arrow" />
              <span>{solution}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
