import React from "react";
import Styles from "../home.module.css";
import { PlanIcon, HandIcon, MaintinIcon, DesignIcon } from "../../../Assets";

const MobileOurProcess = () => {
  const processData = [
    {
      icon: PlanIcon,
      name: "Planning & Research",
    },
    {
      icon: DesignIcon,
      name: "Design & Development",
    },
    {
      icon: HandIcon,
      name: "Stabilisation & Feedback",
    },
    {
      icon: MaintinIcon,
      name: "Maintenance & Support",
    },
  ];
  return (
    <div className={Styles.ourProcessBody}>
      <span style={{ margin: "20px 0", fontSize: "38px", fontWeight: "600" }}>
        Our <span style={{ color: "#0078D1" }}>Process</span>
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap:"10px"
        }}
      >
        {processData.map((res, index) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  border: "5px solid #0078D1",
                  borderRadius: "50%",
                }}
              ></div>
              <div
                style={{
                  height: "2px",
                  width: "10%",
                  background: "#0078D1",
                }}
              ></div>
              <div
                className={Styles.ourProcessText}
                style={{ display: "flex", flexDirection: "row", gap: "10px",margin:"0" }}
              >
                <img style={{ height: "35px" }} src={res.icon} alt="icon" />
                <span style={{ width: "50%" }}>{res.name}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileOurProcess;
