import React, { useEffect, useState } from "react";
import styles from "./navbar.module.css";
import { Logo } from "../../Assets";
import DrawerPage from "../Utils/Drawer";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const navBarRoutes = [
    {
      route: "/#about",
      title: "Our Quality",
    },
    {
      route: "/#ourServices",
      title: "Our Services",
    },
    {
      route: "/#work",
      title: "Work",
    },
    {
      route: "/#feedback",
      title: "Testimonials",
    },
    {
      route: "/#ourStory",
      title: "About Us",
    },
    {
      route: "/case-study",
      title: "Case Study",
    },
  ];
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOption, setSelectionOption] = useState("");
  const location = useLocation();
  const { pathname, hash } = location;

  useEffect(() => {
    navBarRoutes.forEach((res) => {
      if (pathname && pathname.includes("/case-study"))
        setSelectionOption("Case Study");
      else if (hash && res.route.includes(hash)) setSelectionOption(res.title);
    });
  }, [pathname, navBarRoutes, hash]);

  const toggleDrawer = async () => {
    await setIsOpen((prevState) => !prevState);
  };
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      console.log(targetElement.id);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = async (event, sectionId, status = true) => {
    localStorage.setItem("currentSection", sectionId);
    event?.preventDefault();
    if (status) {
      toggleDrawer();
    }
    if (sectionId === "about") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#about";
        return;
      }
      const offsetTop = 700;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "ourStory") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#ourStory";
        return;
      }
      const offsetTop = 4000;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "feedback") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#feedback";
        return;
      }
      const offsetTop = 2500;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "work") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#work";
        return;
      }
      const offsetTop = 5500;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "contact-form") {
      const offsetTop = window.location.href.includes("case-study")
        ? 4000
        : 6200;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "ourServices") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#ourServices";
        return;
      }
      const offsetTop = 2300;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  return (
    <>
      <div
        className={styles.navbarBody}
        style={{ padding: 0, position: "fixed", width: "100%", zIndex: "99" }}
      >
        <div style={{ margin: "1% 4%", cursor: "pointer" }}>
          <a href="/#hero">
            <img src={Logo} alt="CompanyLogo" />
          </a>
        </div>
        <div className={`${styles.navBarRoutes} ${styles.webHide}`}>
          <div
            className={styles.navBarRoutes}
            style={{
              width: "70%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {navBarRoutes.map((res, index) => (
              <a
                href={res.route}
                key={index}
                style={{
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "10px",
                  borderRadius: "10px",
                  color: selectedOption === res.title ? "white" : "black",
                  backgroundColor:
                    selectedOption === res.title ? "#3a9de7" : "transparent",
                  transition: "200ms ease-in-out",
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = "white";
                  e.target.style.backgroundColor = "#0078d1";
                  e.target.style.borderRadius = "10px";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color =
                    selectedOption === res.title ? "white" : "black";
                  e.target.style.backgroundColor =
                    selectedOption === res.title ? "#3a9de7" : "transparent";
                }}
              >
                {res.title}
              </a>
            ))}
          </div>
          <button>
            <a
              href="#contact-form"
              style={{ color: "black", textDecoration: "none" }}
              onMouseEnter={(e) => {
                e.target.style.color = "white";
                e.target.style.backgroundColor = "#0078d1";
                e.target.style.padding = "10px";
                e.target.style.borderRadius = "10px";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "black";
                e.target.style.backgroundColor = "transparent";
              }}
            >
              {" "}
              Contact{" "}
            </a>
          </button>
        </div>
        <button
          style={{
            margin: "1% 5%",
            background: "none",
            fontSize: "25px",
            border: "none",
          }}
          onClick={toggleDrawer}
          className={`${styles.mobileHide}`}
        >
          ☰
        </button>
      </div>
      <DrawerPage
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        navBarRoutes={navBarRoutes}
        scrollToSection={scrollToSection}
      />
    </>
  );
};

export default Navbar;
