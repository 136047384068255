import { LinekdIn_Icon, Logo } from "../../Assets";
import "./newFooter.css";

export const NewFooter = () => {
  const scrollToSection = async (sectionId) => {
    localStorage.setItem("currentSection", sectionId);
    if (sectionId === "about") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#about";
        return;
      }
      const offsetTop = 700;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "ourStory") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#ourStory";
        return;
      }
      const offsetTop = 4000;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "feedback") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#feedback";
        return;
      }
      const offsetTop = 2500;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "work") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#work";
        return;
      }
      const offsetTop = 5500;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "contact-form") {
      const offsetTop = window.location.href.includes("case-study")
        ? 4000
        : 6200;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
    if (sectionId === "ourServices") {
      if (window.location.href.includes("case-study")) {
        window.location.href = "/#ourServices";
        return;
      }
      const offsetTop = 2300;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  const socialIcons = [LinekdIn_Icon];

  return (
    <div className="newFooterContainer">
      <div className="newFooterContainer__logoContainer">
        <img src={Logo} alt="Company Logo" />
        <h3>
          Unlocking Digital Brilliance | Eternalight - Your Partner in Creative
          Solutions
        </h3>

        <p
          style={{
            marginTop: 40,
          }}
        >
          Copyright ©2023 by Eternalight
        </p>
        <p>Privacy Policy Terms & Conditions</p>
      </div>

      {footerPointsColumns.map((column) => (
        <div>
          <FooterPointsColumn
            title={column.title}
            links={column.links}
            scrollToSection={scrollToSection}
          />
        </div>
      ))}

      <div className="footerPointsColumn__connectUsContainer">
        <h2>Connect with us</h2>

        <div>
          {socialIcons.map((icon) => (
            <div
              className="footerPointsColumn__connectUsIcon"
              onClick={() =>
                window.open(
                  "https://in.linkedin.com/company/eternalight-infotech",
                  "_blank"
                )
              }
            >
              <img src={icon} alt="LinkedIn Icon" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const FooterPointsColumn = ({ title, links, scrollToSection }) => (
  <div className="footerPointsColumn__container">
    <h2>{title}</h2>
    <div className="footerPointsColumn__linksContainer">
      {links.map((link, index) => (
        <a
          key={index}
          href={
            link.route ? `/${link.route}` : link.hashId && `/#${link.hashId}`
          }
          onClick={() => {
            if (link.hashId) scrollToSection(link.hashId);
          }}
        >
          {link.name}
        </a>
      ))}
    </div>
  </div>
);

const footerPointsColumns = [
  {
    title: "Quick Links",
    links: [
      {
        hashId: "feedback",
        name: "Testimonials",
      },
      {
        route: "case-study",
        name: "Case Study",
      },
      {
        hashId: "about",
        name: "How we Different",
      },
    ],
  },
  {
    title: "How we Different",
    links: [
      {
        hashId: "about",
        name: "About",
      },
      {
        hashId: "contact-form",
        name: "Contact",
      },
    ],
  },
  {
    title: "Services",
    links: [
      {
        hashId: "ourServices",
        name: "Web Development",
      },
      {
        hashId: "ourServices",
        name: "Mobile App Development",
      },
      {
        hashId: "ourServices",
        name: "Backend Development",
      },
      {
        hashId: "ourServices",
        name: "Cloud Deployment",
      },
      {
        hashId: "ourServices",
        name: "UI/UX Design",
      },
    ],
  },
];
