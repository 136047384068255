import { Arrow } from "../../../Assets";
import "./caseStudyDetails.css";

export const ProblemSection = ({ statement, problemsList }) => {
  return (
    <div className="caseStudyDetails__bottomContentProblemContainer">
      <h2 className="caseStudyDetails__bottomContentSectionHeader">Problem</h2>
      {statement && (
        <p className="caseStudyDetails__bottomContentSectionDescription">
          {statement}
        </p>
      )}
      {problemsList.map((problem) => (
        <div className="caseStudyDetails__bottomContentSectionList">
          <img src={Arrow} alt="Arrow" />
          <span>{problem}</span>
        </div>
      ))}
    </div>
  );
};
