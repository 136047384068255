import { useCallback } from "react";
import { caseStudiesDetails, caseStudyData } from "../caseStudiesData";
import "./caseStudyDetails.css";

export const OtherCaseStudies = ({ currentCaseStudyId }) => {
  const handleClick = useCallback(
    (studyId) => {
      if (currentCaseStudyId === studyId) return;

      const id = caseStudiesDetails.find(
        (caseStudy) => caseStudy.id === studyId
      )?.id;

      if (id) window.location.href = `/case-study-details/${id}`;
      else return;
    },
    [caseStudiesDetails, currentCaseStudyId]
  );

  return (
    <div className="otherCaseStudies__container">
      <h1>Case Studies</h1>
      <div className="otherCaseStudies_containerUnderline" />

      <div className="caseStudiesWrapper">
        {caseStudyData.map((study) => {
          const image = caseStudiesDetails.find(
            (studyDetail) => studyDetail.id === study.id
          )?.companyLogo;

          return (
            <div
              className="otherCaseStudies__caseStudy"
              onClick={() => handleClick(study.id)}
            >
              {image && <img src={image} alt="" />}
              <div>
                <h2>{study.tittle}</h2>
                <p>{study.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
