import React, { useState } from "react";
import { Visit, Hiring, Email } from "../../Assets";
import Styles from "./home.module.css";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const formData = [
    {
      imag: Email,
      title: "Email us",
      contactus: "info@eternalight.in",
    },
    {
      imag: Hiring,
      title: "Call Us",
      contactus: "+918438308022",
    },
    {
      imag: Visit,
      title: "Visit Us",
      contactus: "302, Xion mall, Hinjewadi Phase 1, Pune - 411057",
    },
  ];

  return (
    <div className={Styles.contactFormBody} id="contact-form">
      <span
        style={{ margin: "2px 0", fontSize: "38px", fontWeight: "600" }}
        className={Styles.mobileHide}
      >
        Contact <span style={{ color: "#0078D1", fontWeight: "700" }}>Us</span>
      </span>
      <div className={Styles.infoCard}>
        <span style={{ fontSize: "38px", fontWeight: "600", color: "#171717" }}>
          Info
        </span>
        {formData.map((res) => (
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <img src={res.imag} alt="icon" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1px",
              }}
            >
              <span
                style={{ fontSize: "18", fontWeight: "600", color: "#171717" }}
              >
                {res.title}
              </span>
              <span
                style={{ fontSize: "16", fontWeight: "400", color: "#596080" }}
              >
                {res.contactus}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <span
          style={{ margin: "2px 0", fontSize: "38px", fontWeight: "600" }}
          className={Styles.webHide}
        >
          Contact{" "}
          <span style={{ color: "#0078D1", fontWeight: "700" }}>Us</span>
        </span>
        <span
          style={{
            fontSize: "16px",
            fontWeight: "400",
            color: "#596080",
            width: "80%",
          }}
        >
          Feel free to contact us any time. we will get back to you as soon as
          we can!
        </span>
        {false ? (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Bars
              height="50"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <div class="container">
            <form
              // target="_blank"
              action="https://formsubmit.co/info@eternalight.in"
              method="POST"
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div class="form-group">
                <div
                  class="form-row"
                  style={{ display: "flex", gap: "10px", width: "100%" }}
                >
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    required
                    className={Styles.inputForm}
                  />

                  <input
                    type="email"
                    name="email"
                    className={Styles.inputForm}
                    placeholder="Email Address"
                    required
                  />
                  <input type="hidden" name="_captcha" value="false" />
                  <input type="hidden" name="_next" value="https://eternalight-website.vercel.app/"/>
                </div>
              </div>
              <div class="form-group">
                <textarea
                  placeholder="Your Message"
                  style={{
                    width: "96%",
                    border: "1px solid white",
                    padding: "1% 2%",
                    borderRadius: "10px",
                  }}
                  name="message"
                  rows="10"
                  required
                ></textarea>
              </div>
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#596080",
                  width: "80%",
                }}
              >
                By proceeding, you are agreeing to eternalight Privacy Policy & communication
              </span>
              <button type="submit" className={Styles.themeBtn}>
                Submit Form
              </button>
            </form>
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default ContactForm;
