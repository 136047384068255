import "./caseStudyDetails.css";

export const AboutSection = ({ description, image }) => {
  return (
    <div className="caseStudyDetails__bottomContentSectionContainer">
      {/* left */}
      <div>
        <h2 className="caseStudyDetails__bottomContentSectionHeader">About</h2>
        <p className="caseStudyDetails__bottomContentSectionDescription">
          {description}
        </p>
      </div>

      {/* right image */}
      <img
        className="caseStudyDetails__bottomContentSectionImage aboutImage"
        src={image}
        alt=""
      />
    </div>
  );
};
