import { AiOutlineHome } from "react-icons/ai";
import "../caseStudy.css";
import { caseStudiesDetails } from "../caseStudiesData";
import { colors } from "../../../utils/colors";
import { AboutSection } from "./AboutSection";
import { ProblemSection } from "./ProblemSection";
import { SolutionSection } from "./SolutionSection";
import { ImpactSection } from "./ImpactSection";
import { OtherCaseStudies } from "./OtherCaseStudies";
import "./caseStudyDetails.css";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const CaseStudyDetails = () => {
  const [caseStudy, setCaseStudy] = useState();

  const params = useParams();

  useEffect(() => {
    if (params && params.id) {
      const study = caseStudiesDetails.find(
        (caseStudy) => caseStudy.id === params.id
      );

      if (study) setCaseStudy(study);
    }
  }, [params?.id]);

  if (!caseStudy) return <h1>Loading...</h1>;

  return (
    <div className="caseStudyDetails__container themeColor1">
      <div className=" caseDetails caseStudyDetails__top">
        <div>
          <a href="/">
            <AiOutlineHome />
          </a>
          <span>
            <a href="/case-study">/ Case Study</a>/ {caseStudy.name}{" "}
          </span>{" "}
        </div>
        <img src={caseStudy.companyLogo} />
      </div>

      <div className="caseStudyDetails__bottom">
        {/* Content */}
        <div className="caseStudyDetails__bottomContent">
          {/* Heading */}
          <h1>{caseStudy.heading}</h1>

          <h3>Technologies Used</h3>

          {/* Technologies */}
          <div className="caseStudyDetails__bottomContentTechs">
            {caseStudy.technologiesUsed.map((tech, index) => (
              <img
                key={index}
                src={tech}
                alt={`tech_${index}`}
                height={35}
                width={35}
              />
            ))}
          </div>

          {/* About Section */}
          <AboutSection
            description={caseStudy.aboutDescription}
            image={caseStudy.aboutImage}
          />

          {/* Banner Big Image */}
          <div className="caseStudyDetails__bottomContentBigBanner">
            <img src={caseStudy.bigImage} alt="" />
          </div>

          {/* Problems */}
          <ProblemSection
            problemsList={caseStudy.problemsList}
            statement={caseStudy.problemStatement}
          />

          {/* Solution */}
          <SolutionSection
            image={caseStudy.solutionLeftImage}
            solutionsList={caseStudy.solutionsList}
            statement={caseStudy.solutionStatement}
            subStatement={caseStudy.solutionSubStatement}
          />

          {/* Impact Section */}
          <ImpactSection
            image={caseStudy.impactsRightImage}
            impactsList={caseStudy.impactsList}
          />
        </div>

        {/* Other Case Studies */}
        <OtherCaseStudies currentCaseStudyId={caseStudy.id} />
      </div>
    </div>
  );
};
