import React from "react";
import Styles from "./home.module.css";
import { PlanIcon, HandIcon, MaintinIcon, DesignIcon } from "../../Assets";

const OurProcess = () => {
  const processData = [
    {
      icon: PlanIcon,
      name: "Planning & Research",
    },
    {
      icon: DesignIcon,
      name: "Design & Development",
    },
    {
      icon: HandIcon,
      name: "Stabilisation & Feedback",
    },
    {
      icon: MaintinIcon,
      name: "Maintenance & Support",
    },
  ];
  return (
    <div className={Styles.ourProcessBody}>
      <span style={{ margin: "20px 0", fontSize: "38px", fontWeight: "600" }}>
        Our <span style={{ color: "#0078D1" }}>Process</span>
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {processData.map((res, index) => {
          return (
            <div
              style={{
                width: "25%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {index % 2 !== 1 ? (
                <div
                  className={Styles.ourProcessText}
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <img style={{ height: "35px" }} src={res.icon} alt="icon" />
                  <span style={{width:"50%"}}>{res.name}</span>
                </div>
              ) : (
                <span
                  className={Styles.ourProcessText}
                  style={{
                    border: "2px solid #fff",
                    fontSize: "50px",
                    fontWeight: "600",
                    color: "#596080",
                  }}
                >
                  0{index + 1}
                </span>
              )}
              {/* Vertical Line */}
              {index % 2 !== 1 && (
                <div
                  style={{
                    height: "20px",
                    width: "3px",
                    background: "#0078D1",
                  }}
                ></div>
              )}
              {/* Round Circle */}
              {index % 2 !== 1 && (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "5px solid #0078D1",
                    borderRadius: "50%",
                  }}
                ></div>
              )}
              {/* Conditon in which there is no vertical line required */}
              {index % 2 === 1 && (
                <div
                  style={{ height: "50px", width: "3px", background: "none" }}
                ></div>
              )}

              {/* Horizonatal Line */}
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  background: "#0078D1",
                }}
              ></div>

              {/* Same Condition as per the above for the alternate card */}
              {index % 2 !== 1 && (
                <div
                  style={{ height: "40px", width: "3px", background: "none" }}
                ></div>
              )}
              {index % 2 === 1 && (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "5px solid #0078D1",
                    borderRadius: "50%",
                  }}
                ></div>
              )}
              {index % 2 === 1 && (
                <div
                  style={{
                    height: "20px",
                    width: "3px",
                    background: "#0078D1",
                  }}
                ></div>
              )}
              {index % 2 === 1 ? (
                <div
                  className={Styles.ourProcessText}
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <img style={{ height: "35px" }} src={res.icon} alt="icon" />
                  <span style={{width:"50%"}}>{res.name}</span>
                </div>
              ) : (
                <span
                  className={Styles.ourProcessText}
                  style={{
                    border: "2px solid #fff",
                    fontSize: "50px",
                    fontWeight: "600",
                    color: "#596080",
                  }}
                >
                  0{index + 1}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurProcess;
