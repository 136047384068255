import { useCallback, useMemo } from "react";
import "./caseStudyCard.css";
import { caseStudiesDetails } from "../caseStudiesData";

export const CaseStudyCard = ({ caseStudyData }) => {
  const description = useMemo(
    () =>
      caseStudyData?.description && caseStudyData?.description.length > 250
        ? `${caseStudyData?.description.slice(0, 250)}...`
        : caseStudyData?.description,
    [caseStudyData?.description]
  );

  const handleClick = useCallback(() => {
    const id = caseStudiesDetails.find(
      (caseStudy) => caseStudy.id === caseStudyData.id
    )?.id;

    if (id) window.location.href = `/case-study-details/${id}`;
    else return;
  }, [caseStudyData?.id]);

  return (
    <div className="cardContainer" onClick={handleClick}>
      {/* Image/video */}
      <div className="cardContainer__top">
        {caseStudyData.type === "image" && (
          <img
            className="cardImageVideo"
            src={caseStudyData.image}
            alt="CaseStudyImage"
          />
        )}
        {caseStudyData.type === "video" && (
          <video
            className="cardImageVideo"
            // ref={videoRef}
            loop
            autoPlay
            muted
            playsInline
          >
            <source src={caseStudyData.image} type="video/mp4" />
          </video>
        )}
      </div>

      {/* Content */}
      <div className="cardContainer__bottom">
        <h1>{caseStudyData?.tittle}</h1>

        <p className="cardContainer__bottomDescription">{description}</p>

        <p className="readMore">Read More...</p>

        <div className="cardContainer__bottomSeparator" />

        <div className="cardContainer__bottomTags">
          {caseStudyData?.section.map((res, index) => (
            <div key={index}>{res}</div>
          ))}
        </div>

        <h3>Technologies</h3>

        <div className="cardContainer__bottomTechs">
          {caseStudyData.technologies.map((tech) => (
            <img src={tech} style={{ objectFit: "contain" }} alt="icons" />
          ))}
        </div>
      </div>
    </div>
  );
};
