import React, { useState } from "react";
import { AiOutlineHome } from "react-icons/ai";
// import {
//   CS1,
//   CS2,
//   CS3,
//   CS4,
//   WordPressIcon,
//   FigmaIcon,
//   CloudFrontIcon,
//   GST,
//   KinderPest,
//   TripeurImage,
//   NodeJs,
//   ReactJs,
//   AngularJs,
// } from "../../Assets";
// import CaseStudyCard from "./caseStudyCard";
import { CaseStudyCard as CaseStudyTile } from "./CaseStudyCard/caseStudyCard";
// import DiscussForm from "../Utils/DiscussForm";
// import ExpMngVideo from "../../Assets/video/expenseManagment.webm";
// import Xetlink from "../../Assets/video/Xetlink.webm";
// import Spotsaas from "../../Assets/video/Spotsaas.webm";
import ContactForm from "../Home/ContactForm";
import "./caseStudy.css";

// import TripeurCaseStudy from "./TripeurCaseStudy";
import { caseStudyData } from "./caseStudiesData";
const CaseStudy = () => {
  // const topSectionData = ['All', 'Back-end', 'Design', 'FinTech', 'Front-end', 'Healthcare', ' iGaming', ' Maintenance', 'Mobile', 'Web']
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="themeColor1 topbar">
        <div
          style={{
            color: "#596080",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1px",
          }}
        >
          <a style={{ textDecoration: "none" }} href="/">
            <AiOutlineHome />
          </a>
          <span> / Our Case Study </span>{" "}
        </div>
        <span className="heading">Our Case Study</span>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 40,
          marginTop: 30,
          marginBottom: 30,
          flexWrap: "wrap",
          maxWidth: "90vw",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {caseStudyData.map((res) => (
          <CaseStudyTile caseStudyData={res} />
        ))}
      </div>

      {/* <div className="cardBody">
        {caseStudyData.map((res) => (
          <CaseStudyCard caseStudyData={res} />
        ))}
      </div> */}
      <ContactForm />
    </div>
  );
};

export default CaseStudy;
