import React, { useState } from "react";
import {
  WebService,
  MobileImage,
  BackendImage,
  CloudDevelopment,
  UIUXImage,
} from "../../../Assets";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";

const MobileServices = () => {
  const defaultServices = [
    {
      tittle: "Web Development",
      image: WebService,
      description:
        "Here at Eternalight, we create web applications you'll adore. Our commitment to cutting-edge technology ensures your application runs seamlessly on 99% of browsers.",
      area: [
        {
          subTittle: "Responsive",
          subDesc:
            "Responsive web design utilizes flexible layouts and fluid grids, enabling the website's content and structure to dynamically adjust to different screen sizes, promoting accessibility and usability.",
        },
        {
          subTittle: "Cross browser compatibility",
          subDesc:
            "Web developers prioritize cross-browser compatibility by testing and optimizing their code to work seamlessly on popular browsers like Chrome, Firefox, Safari, and Internet Explorer, ensuring a broad user base can access and interact with the site without encountering compatibility issues.",
        },
        {
          subTittle: "Page Speed",
          subDesc:
            "Optimizing page speed involves minimizing file sizes, leveraging browser caching, and employing efficient coding practices to enhance the loading speed of web pages, ultimately enhancing user satisfaction and engagement.",
        },
      ],
    },
    {
      tittle: "Mobile Application Development",
      image: MobileImage,
      description:
        "We specialize in crafting cutting-edge mobile applications that redefine user experiences. Our expert team harnesses the latest technologies to bring your app ideas to life. Explore how we can turn your mobile vision into a reality.",
      area: [
        {
          subTittle: "Native App Development",
          subDesc:
            "Native app development focuses on utilizing the native programming languages and tools of a target platform, such as Swift for iOS or Kotlin for Android, ensuring optimal performance, responsiveness, and integration with device features.",
        },
        {
          subTittle: "Hybrid App Development",
          subDesc:
            "With hybrid mobile app development, frameworks like React Native or Xamarin facilitate the creation of apps that leverage web technologies while providing access to device features, offering a balance between development efficiency and native-like performance.",
        },
        {
          subTittle: "IOT Application",
          subDesc:
            "IoT mobile applications enable seamless integration with a wide range of connected devices, providing users with real-time data, automation, and control over their smart home, industrial equipment, or other IoT-enabled systems.",
        },
      ],
    },
    {
      tittle: "Backend Development",
      image: BackendImage,
      description:
        "We excel in Backend Development, crafting scalable APIs, designing efficient databases, managing notifications, solving intricate workflows, and implementing microservices architecture to power your digital solutions.",
      area: [
        {
          subTittle: "Scalability",
          subDesc:
            "Scalability in the backend involves designing and implementing architecture that can efficiently scale resources, such as servers and databases, to accommodate a growing user base or increased computational needs, maintaining responsiveness and reliability.",
        },
        {
          subTittle: "Resilience",
          subDesc:
            "A resilient backend architecture employs redundancy, fault tolerance, and recovery mechanisms, enabling the system to adapt and recover from failures, whether caused by hardware issues, network problems, or other unexpected challenges, to maintain uninterrupted service.",
        },
        {
          subTittle: "Robust",
          subDesc:
            "A robust backend is designed to gracefully handle edge cases, validate inputs, and manage errors, ensuring reliability and stability in the face of unpredictable conditions, ultimately contributing to a resilient and dependable overall system.",
        },
      ],
    },
    {
      tittle: "Cloud Deployment",
      image: CloudDevelopment,
      description:
        "Our in-house cloud experts are here to assist you in setting up robust CI/CD pipelines and Kubernetes deployments, fortified with the latest cloud solutions from AWS, GCP, and Azure. We prioritize security at every step.",
      area: [
        {
          subTittle: "CI/CD Driven Approach",
          subDesc:
            "A cloud CI/CD-driven approach leverages cloud services for Continuous Integration (CI) and Continuous Delivery (CD), automating software development processes to enable faster and more efficient deployment pipelines.",
        },
        {
          subTittle: "Monitoring",
          subDesc:
            "Utilizing app monitoring tools allows for real-time visibility into the health and performance of cloud-based applications, enabling proactive problem detection, efficient troubleshooting, and the optimization of resource utilization for a seamless user experience.",
        },
        {
          subTittle: "Security",
          subDesc:
            "Implementing robust cloud security measures involves encryption, access controls, identity management, and continuous monitoring, ensuring the confidentiality, integrity, and availability of information stored and processed in cloud services.",
        },
      ],
    },
    {
      tittle: "UI/UX Design",
      image: UIUXImage,
      description:
        "We assist in crafting products from inception, capturing the essence, and delivering unique and adored designs to clients. Employing vector-based crafting ensures perfection across all devices.",
      area: [
        {
          subTittle: "Flow Experience",
          subDesc:
            "A well-crafted UI/UX flow experience ensures that users can easily and intuitively navigate through the interface, achieving their goals efficiently while enjoying a visually appealing and user-friendly interaction with the application or website.",
        },
        {
          subTittle: "Vector Graphics",
          subDesc:
            "Employing UI/UX vector graphics enables designers to create sharp and scalable visuals that enhance the overall user experience, ensuring clarity and consistency across various devices and display densities.",
        },
        {
          subTittle: "Color & Fonts",
          subDesc:
            "The significance of color in UI/UX lies in its ability to communicate information, evoke emotions, and create a cohesive design language. Fonts, chosen for their style and legibility, contribute to the overall readability and user comprehension, forming essential elements in crafting an effective and visually pleasing user interface.",
        },
      ],
    },
  ];

  const [divs, setDivs] = useState(defaultServices);

  const toggleDiv = (id) => {
    debugger;
    const updatedArray = [...divs];
    updatedArray[id]["open"] = !updatedArray[id]?.open;
    setDivs(updatedArray);
  };

  return (
    <div
      className="themeColor1"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "30px",
        padding: "5%",
      }}
      id="ourServices"
    >
      <div>
        <div
          style={{ fontSize: "38px", fontWeight: "600", textAlign: "center" }}
        >
          Services{" "}
          <span
            style={{ fontSize: "38px", fontWeight: "600", color: "#0078D1" }}
          >
            {" "}
            We Offer
          </span>
        </div>
      </div>

      <div style={{ width: "100%",display:"flex",flexDirection:"column",gap:"5px" }}>
        {divs.map((div, index) => (
          <div key={index}>
            <div
              onClick={() => toggleDiv(index)}
              style={{
                cursor: "pointer",
                backgroundColor: "#041459",
                color:"#fff",
                padding: "10px",
                display:"flex",
                justifyContent:"space-between",
                alignItems:"center",
                borderRadius:"10px"
              }}
            >
              <span>
               {div.tittle}
              </span>
              <div>{div.open ? <FaCaretDown /> : <FaCaretUp />}</div>
            </div>
            {div.open && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  boxShadow: "1px 1px 4px 0px #888888",
                  padding:"10px",
                  borderRadius:"10px",
                  margin:"10px 0"

                }}
              >
                <img src={div?.image} alt="WebService" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "32px",
                      fontWeight: "600",
                      color: "#171717",
                    }}
                  >
                    {/* {selectedTab?.tittle} */}
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#596080",
                    }}
                  >
                    {div?.description}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      margin: "40px 0",
                    }}
                  >
                    {div?.area.map((res) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                        }}
                      >
                        <span>➡️</span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#171717",
                            }}
                          >
                            {res.subTittle}
                          </span>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#596080",
                            }}
                          >
                            {res.subDesc}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileServices;
