import {
  AngularJs,
  CloudFrontIcon,
  Comp1,
  CSAbout,
  CSBanner,
  CSImpact,
  CSSolution,
  FigmaIcon,
  GST,
  KinderPest,
  NodeJs,
  ReactJs,
  SAbout,
  SBanner,
  SImpact,
  SpotSaaS,
  SSolution,
  TAbout,
  TImpact,
  TProblem,
  TripeurAbout,
  TripeurImage,
  TripeurImpact,
  TripeurProblem,
  TripeurSolution,
  TSolution,
  WordPressIcon,
  XAbout,
  XImpact,
  XProblem,
  XSolution,
} from "../../Assets";
import ExpMngVideo from "../../Assets/video/expenseManagment.webm";
import Xetlink from "../../Assets/video/Xetlink.webm";
import Spotsaas from "../../Assets/video/Spotsaas.webm";

export const caseStudyData = [
  {
    id: "1",
    tittle: "Tripeur",
    description:
      "Tripeur is an end-to-end corporate travel management platform that uses advanced Artificial Intelligence and Machine Learning algorithms to drive efficiency, ease of use, personalization and transparency for business travel by employees and executives alike.",
    section: ["Mobile", "Front-end", "Back-end"],
    image: TripeurImage,
    type: "image",
    technologies: [NodeJs, AngularJs, ReactJs],
  },
  {
    id: "2",
    tittle: "Tripeur GST Reconcile",
    description:
      "Tripeur is an end-to-end corporate travel management platform that uses advanced Artificial Intelligence and Machine Learning algorithms to drive efficiency, ease of use, personalization and transparency for business travel by employees and executives alike.",
    section: ["Mobile", "Front-end", "Back-end"],
    image: GST,
    type: "image",
    technologies: [
      NodeJs,
      AngularJs,
      "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
    ],
  },
  {
    id: "3",
    tittle: "Tripeur Expense Management",
    description:
      "Tripeur is an end-to-end corporate travel management platform that uses advanced Artificial Intelligence and Machine Learning algorithms to drive efficiency, ease of use, personalization and transparency for business travel by employees and executives alike.",
    section: ["Mobile", "Front-end", "Back-end"],
    image: ExpMngVideo,
    type: "video",
    technologies: [
      NodeJs,
      ReactJs,
      "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
    ],
  },
  {
    id: "4",
    tittle: "Xetlink",
    description:
      "Xetlink Technology Inc has built Xetlink AI, a real-time multilingual communication solution for video conferencing. The technology provides speech-to-text and speech-to-speech translation, meeting transcript analysis, and transcription capabilities, allowing individuals and organizations to conduct meetings with people who speak different languages without the need for a translator.",
    section: ["Mobile", "Front-end", "Back-end"],
    image: Xetlink,
    type: "video",
    technologies: [
      NodeJs,
      "https://taglineinfotech.com/wp-content/uploads/2022/08/puppet.svg",
      "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
    ],
  },
  {
    id: "5",
    tittle: "Spotsaas",
    description:
      "SpotSaaS is a software recommendation platform where we connect buyers and sellers of software products. We help the software buyers make a better product decision based on unbiased reviews, software comparisons, social popularity, and buyers guide.",
    section: ["Mobile", "Front-end", "Back-end"],
    image: Spotsaas,
    type: "video",
    technologies: [
      NodeJs,
      "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
      ReactJs,
    ],
  },
  {
    tittle: "Kindred Pest Control",
    description:
      "Kindred operates as a residential pest control company, offering a range of services including pest control, bed bug treatments, indoor flea treatments, and German roach treatments. Their service model encompasses both one-time service requests and subscription-based plans.",
    section: ["Mobile", "Front-end", "Back-end"],
    image: KinderPest,
    type: "image",
    technologies: [WordPressIcon, FigmaIcon, CloudFrontIcon],
  },
];

export const caseStudiesDetails = [
  {
    id: "1",
    name: "Tripeur",
    companyLogo: Comp1,
    heading: "How Tripeur Optimized Booking Process",
    technologiesUsed: [NodeJs, AngularJs, ReactJs],
    aboutDescription:
      "Tripeur is an end-to-end corporate travel management platform that uses advanced Artificial Intelligence and Machine Learning algorithms to drive efficiency, ease of use, personalization and transparency for business travel by employees and executives alike.",
    aboutImage: CSAbout,
    bigImage: CSSolution,
    problemStatement:
      "Tripeur relies on a range of GDS and Aggregators to source flight content for customer display, but their platform faced significant technical challenges that hindered further scalability.",
    problemsList: [
      "Sluggish response times from aggregator flight search results.",
      "Absence of new airline options in aggregator listings.",
      "An error rate of approximately 3-10% in the flight booking flow.",
      "Inadequate error response handling from aggregators.",
      "Limited availability of discounts and offers from aggregators.",
    ],
    solutionStatement:
      "As part of the solution they were looking for technology partners who can have the experience working with the Travel industry and build a solid platform that serves millions of customers.",
    solutionLeftImage: CSBanner,
    solutionSubStatement:
      "As part of the discussion we implemented the solutions below.",
    solutionsList: [
      "Established direct connectivity with Low-Cost Carrier (LCC) airlines.",
      "Integrated seamlessly with the discount and offer systems of LCC airlines.",
      "Deployed a scalable solution using Kubernetes and a serverless architecture.",
      "Implemented result caching for frequently searched flights.",
      "Additionally, developed a proof of concept (POC) for flight price prediction to assist users in selecting the most cost-effective flight options.",
    ],
    impactsList: [
      "Achieved comprehensive coverage, encompassing nearly 99% of airline listings.",
      "Significantly improved the search-to-booking ratio, soaring from 40% to 60%.",
      "Experienced a remarkable increase in Customer Satisfaction (CSAT) scores, surging from 32% to 62%.",
      "Successfully onboarded three new clients within just two months, offering a range of discounts and airlines to choose from.",
    ],
    impactsRightImage: CSImpact,
  },
  {
    id: "2",
    name: "Tripeur GST Reconcile",
    companyLogo: Comp1,
    heading: "Your Ultimate GST Reconciliation Companion",
    technologiesUsed: [
      NodeJs,
      AngularJs,
      "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
    ],
    aboutDescription:
      "Tripeur is an end-to-end corporate travel management platform that uses advanced Artificial Intelligence and Machine Learning algorithms to drive efficiency, ease of use, personalization and transparency for business travel by employees and executives alike.",
    aboutImage: TAbout,
    bigImage: TProblem,
    problemStatement: "",
    problemsList: [
      "In 2017, the introduction of the Goods and Services Tax (GST) marked a groundbreaking shift in India's tax landscape, aiming to simplify taxation with the principle of 'one nation, one tax.' Intriguingly, each year, companies lose hundreds of crores in GST credit due to the lack of proper reconciliation among multiple parties.",
      "Amid the challenges presented by the COVID-19 pandemic, Tripeur recognized the need to create a dedicated product that could facilitate GST tax reconciliation for travel-related invoices among various stakeholders, including companies, airlines, hotels, railways, and bus vendors.",
      "The complexity of the task was exacerbated by the fact that these invoices arrive in various formats. On average, companies receive a staggering 150,000 invoices annually. Manually reconciling taxes for each invoice would have been a logistical nightmare for finance departments, leading many larger companies to forego claiming their GST input credits.",
    ],
    solutionStatement:
      "Tripeur embarked on a project to meet a crucial requirement: the development of a product that could automate the entire process from start to finish, eliminating the need for manual intervention. The system's primary functions included invoice ingestion, data parsing, data comparison with travel vendor invoices, and the generation of comprehensive reconciliation reports.",
    solutionSubStatement: "",
    solutionLeftImage: TSolution,
    solutionsList: [
      "To streamline the onboarding process, we set up separate email addresses for each Tripeur customer and integrated them with Freshdesk. This setup ensured that whenever a vendor's email landed in Freshdesk, our system seamlessly ingested the invoices, securely storing them in an S3 bucket.",
      "To facilitate data understanding, we designed a PDF/Image/text parser service. This service parsed invoice data and transformed it into a user-friendly format.",
      "In addition, we developed a service capable of identifying missing invoices and retrieving them from the vendor portal or sending periodic email reminders.",
      "A pivotal component of our solution was the reconciliation system. This system compared data and provided detailed insights, highlighting missing invoices, tax amount discrepancies, and unclaimed credit amounts, offering a comprehensive overview of the financial landscape.",
    ],
    impactsList: [
      "Thanks to a successful GST reconciliation, the product launch resulted in a 6% expansion of their customer base.",
      "The GST reconciliation product enabled companies to successfully claim approximately 1 million dollars in credit.",
      "Product helped companies to find approximately 10K missing invoices.",
    ],
    impactsRightImage: TImpact,
  },
  {
    id: "3",
    name: "Tripeur Expense Management",
    companyLogo: Comp1,
    heading: "Tripeur's Smooth Expense Processing",
    technologiesUsed: [
      NodeJs,
      ReactJs,
      "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
    ],
    aboutDescription:
      "Tripeur is an end-to-end corporate travel management platform that uses advanced Artificial Intelligence and Machine Learning algorithms to drive efficiency, ease of use, personalization and transparency for business travel by employees and executives alike.",
    aboutImage: TripeurAbout,
    bigImage: TripeurProblem,
    problemStatement: "",
    problemsList: [
      "Expense management plays a crucial role in corporate travel, and every organization has its unique approach to handle travel-related expenditures. Some issue corporate credit cards or provide cash, while others require employees to front the expenses and seek reimbursement later.",
      "Over the past six years, Tripeur has been a trusted provider of travel services. In recent years, they've been receiving requests from clients to develop an in-house expense system to streamline the management of travel-related costs. However, this endeavour posed various challenges, such as dealing with diverse types of invoices, multiple currencies, expense limits, credit card spending, and advance cash disbursements. All of these components needed to seamlessly integrate with the existing Tripeur travel system.",
      "Tripeur's objective was clear: to offer a one-click solution for uploading expense invoices, simplifying the reimbursement process and sparing users from lengthy and complicated procedures.",
    ],
    solutionStatement: "",
    solutionSubStatement: "",
    solutionLeftImage: TripeurSolution,
    solutionsList: [
      "As part of our solution, Tripeur conducted a thorough evaluation of the 'buy versus build' approach and found that there were no existing systems offering comprehensive functionality all under one roof. Hence, Tripeur made the strategic decision to develop the system in-house.",
      "We began by creating an Invoice parser service designed to identify various aspects of invoices, such as their type, currency, expense items, and the detection of fraud and duplicate invoices. This initiative led to an impressive 89% improvement in the system's accuracy.",
      "To simplify the expense management process, we developed a mobile app that allowed users to capture invoice images and queue them for parsing. This app incorporated an image optimization algorithm to enhance the clarity of unclear invoices.",
      "We introduced automation into the system, ensuring that once all the invoices were uploaded, they were aggregated into a single location, enabling users to effortlessly generate their expense reports.",
      "Furthermore, we enriched the system with additional features, including approvals, reporting capabilities, and notifications, all aimed at enhancing the user experience and streamlining their journey.",
    ],
    impactsList: [
      "Achieved a 40% increase in adoption among existing customers within the first three months.",
      "Enhanced invoice parsing accuracy from 65% to an impressive 89%, significantly reducing the need for manual intervention in expense creation.",
      "Users consistently submitted their expense reports in an average time of approximately six minutes.",
    ],
    impactsRightImage: TripeurImpact,
  },
  {
    id: "4",
    name: "Xetlink",
    companyLogo:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxeuJFCuqw9XbXgIGHZz5Y3a6roPpxjIHn9g&s",
    heading: "Xetlink Voice-To-Language Conversion",
    technologiesUsed: [
      NodeJs,
      "https://taglineinfotech.com/wp-content/uploads/2022/08/puppet.svg",
      "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
    ],
    aboutDescription:
      "Xetlink Technology Inc has built Xetlink AI, a real-time multilingual communication solution for video conferencing. The technology provides speech-to-text and speech-to-speech translation, meeting transcript analysis, and transcription capabilities, allowing individuals and  organizations to conduct meetings with people who speak different  languages without the need for a translator.",
    aboutImage: XAbout,
    bigImage: XProblem,
    problemStatement: "",
    problemsList: [
      "As a fascinating fact, approximately 55 million meetings occur worldwide every day. Many of these meetings involve participants from various locations. While English serves as a common global language, it's interesting to note that 75% of the world's population doesn't speak English. To address this challenge, Xetlink embarked on the mission to develop a product capable of supporting meetings in multiple languages in real-time.",
      "A pivotal aspect of this solution was the need to capture the voice of the speaker in each meeting. To achieve this, Xetlink aimed to create a bot that could autonomously join meetings, record conversations, and then pass the recorded content to a converter for processing.",
      "Another crucial challenge was the post-recording phase. Once the voice was recorded and converted into text, the bot needed to be equipped with the capability to translate the text into the user's preferred language, making it a comprehensive language-agnostic solution for seamless global communication.",
    ],
    solutionStatement: "",
    solutionSubStatement: "",
    solutionLeftImage: XSolution,
    solutionsList: [
      "As part of their solution, Xetlink sought the development of a bot capable of participating in meetings, capturing the active speaker's voice, transcribing chat conversations, and forwarding this information to a translation service.",
      "To achieve this, we implemented automation bots for popular platforms like Google Meet, Webex, and Microsoft Teams. These bots utilized Puppeteer to launch browsers and join meetings, mimicking real participants. Once the bot identified the active speaker, it streamed the audio to a server.",
      "We also crafted Puppeteer scripts to capture active users, chat text, and monitor the activity of departing meeting participants, transmitting all this data via a websocket.",
      "A websocket was created to continuously stream audio events, ensuring that users at the other end received live, translated text in their chosen language.",
      "Furthermore, we designed a bot to simulate user voices using the FFmpeg library, allowing for voice conversion between different languages.",
      "To maintain bot anonymity and bypass security measures, we implemented Puppeteer security bypass methodologies to avoid detection during Google Meet and Microsoft Teams calls.",
    ],
    impactsList: [
      "Xetlink successfully diversified its service offering across multiple meeting providers, breaking free from their previous restriction to Zoom alone. This strategic expansion had a significant impact, contributing to an 8% increase in their overall revenue.",
      "Furthermore, the introduction of premium features, such as the ability to simulate bot voices, provided Xetlink with a notable competitive advantage over their industry rivals, further solidifying their position in the mark.",
      "Previously, Xetlink relied on Windows-based automation for meeting participation. However, with the transition to a Linux-based solution, they achieved a substantial 22% reduction in operational costs. Subsequently, they leveraged a Docker-based system to efficiently scale their traffic, enhancing their ability to handle increased demand and further optimize their operations.",
    ],
    impactsRightImage: XImpact,
  },
  {
    id: "5",
    name: "Spotsaas",
    companyLogo: SpotSaaS,
    heading: "SaaSVue Unified Visibility Across Platforms",
    technologiesUsed: [
      NodeJs,
      "https://taglineinfotech.com/wp-content/uploads/2022/02/mongodb-logo.webp",
      ReactJs,
    ],
    aboutDescription:
      "SpotSaaS is a software recommendation platform where we connect buyers and sellers of software products. We help the software buyers make a better product decision based on unbiased reviews, software comparisons, social popularity, and buyers guide.",
    aboutImage: SAbout,
    bigImage: SSolution,
    problemStatement:
      "SpotSaaS is a software recommendation platform where we connect buyers and sellers of software products. We help the software buyers make a better product decision based on unbiased reviews, software comparisons, social popularity, and buyers guide.",
    problemsList: [
      "On average, each company relies on more than 20 SaaS (Software as a Service) applications to facilitate their day-to-day operations, spanning from recruitment to sales.",
      "Selecting the perfect software for a specific use case can be quite challenging when faced with a multitude of over a thousand options. Sometimes, the ideal software solutions are available, but due to insufficient research, companies may inadvertently choose poorly suited software.",
      "While numerous videos and blogs provide insights on software and product selection, maintaining up-to-date content can be a significant challenge. Additionally, influencers often exhibit bias towards their preferred software solutions.",
      "SpotSass initially developed the MVP (Minimum Viable Product) in-house, but encountered performance issues, primarily due to handling extensive data. They sought to enhance the user experience by improving API response times.",
    ],
    solutionStatement: "",
    solutionSubStatement: "",
    solutionLeftImage: SBanner,
    solutionsList: [
      "SpotSaaS recognized the numerous challenges associated with software procurement and took the initiative to develop a comprehensive tool. This tool allows companies to efficiently search for software solutions and their respective features. Users can also compare features and pricing among multiple vendors, empowering them to make informed and precise decisions.",
      "To optimize API response times, We implemented a robust structural caching system utilizing Redis and MongoDB. We rearchitected multiple APIs to effectively store caching data and introduced burst strategies to enhance performance.",
      "In addition, the team undertook a significant refactoring effort, addressing more than 40 APIs and their input parameters. They introduced pagination and additional filters to provide rich functionality on the frontend, improving the overall user experience.",
      "Furthermore, We made significant enhancements by streamlining legacy queries. This involved eliminating multiple cross-joins on tables and implementing multiple indexes on the MongoDB database, resulting in improved database performance.",
    ],
    impactsList: [
      "By reducing API response times from approximately 6 seconds to a speedy 400 milliseconds, the user engagement and active time on the website significantly increased.",
      "Furthermore, after the comprehensive API refactoring efforts, the websites page load time was dramatically reduced from about 9 seconds to a swift 1.5 seconds, further enhancing the overall user experience.",
      "Additionally, these improvements led to a commendable 12% reduction in database costs, alongside notable enhancements in the efficiency of database queries.",
    ],
    impactsRightImage: SImpact,
  },
];
