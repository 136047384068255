import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import { ImCancelCircle } from "react-icons/im";
import { FaLightbulb } from "react-icons/fa";
import { FaNetworkWired } from "react-icons/fa";
import { VscFeedback } from "react-icons/vsc";
import { SiOnlyoffice } from "react-icons/si";
import { FaLaptopCode } from "react-icons/fa6";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { MdOutlineMiscellaneousServices } from "react-icons/md";

import "react-modern-drawer/dist/index.css";

const DrawerPage = ({
  isOpen,
  toggleDrawer,
  navBarRoutes,
  scrollToSection,
}) => {
  console.log(window.location.href.includes("case-study"));
  return (
    <div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        style={{ background: "#cfebff" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "5%",
            color: "black",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "600",
              cursor: "pointer",
              width: "70%",
            }}
          >
            {/* Menu */}
          </span>
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={toggleDrawer}
          >
            <ImCancelCircle style={{ fontSize: "20px" }} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "5% 10%",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          <div
            onClick={(e) => scrollToSection(e, "about")}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaLightbulb />
            <span>Our Quality</span>
          </div>
          <div
            onClick={(e) => scrollToSection(e, "ourServices")}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MdOutlineMiscellaneousServices />
            <span>Our Services</span>
          </div>
          <div
            onClick={(e) => scrollToSection(e, "work")}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaNetworkWired />
            <span>Work</span>
          </div>
          <div
            onClick={(e) => scrollToSection(e, "feedback")}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VscFeedback />
            <span>Testimonials</span>
          </div>
          <div
            onClick={(e) => scrollToSection(e, "ourStory")}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SiOnlyoffice />
            <span>About Us</span>
          </div>
          <a
            href={`${navBarRoutes[5]?.route}`}
            style={{
              cursor: "pointer",
              textDecoration: "none",
              color: "black",
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={toggleDrawer}
          >
            <FaLaptopCode />
            <span>{navBarRoutes[5]?.title}</span>
          </a>
          <div
            onClick={(e) => scrollToSection(e, "contact-form")}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MdOutlinePhoneAndroid />
            <span>Contact Us </span>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default DrawerPage;
