import { Arrow } from "../../../Assets";
import "./caseStudyDetails.css";

export const ImpactSection = ({ impactsList, image }) => {
  return (
    <div className="caseStudyDetails__bottomContentSectionContainer3">
      {/* left */}
      <div>
        <h2 className="caseStudyDetails__bottomContentSectionHeader">Impact</h2>
        {impactsList.map((impact) => (
          <div className="caseStudyDetails__bottomContentSectionList">
            <img src={Arrow} alt="Arrow" />
            <span>{impact}</span>
          </div>
        ))}
      </div>

      {/* right image */}
      <img
        src={image}
        height={350}
        width={390}
        alt=""
        className="caseStudyDetails__bottomContentSectionImage"
      />
    </div>
  );
};
