import React from "react";
import { HeroImage1, HeroImage2, HeroImage3 } from "../../Assets";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function CarouselPage() {
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showArrows={false}
      showStatus={false}
      showThumbs={false}
    >
      <div>
        <img src={HeroImage1} alt="" style={{borderRadius:"10px"}} />
      </div>
      <div>
        <img src={HeroImage2} alt="" style={{borderRadius:"10px"}} />
      </div>
      <div>
        <img src={HeroImage3} alt="" style={{borderRadius:"10px"}} />
      </div>
    </Carousel>
  );
}

export default CarouselPage;
