import React, { useEffect, useState } from "react";
import Styles from "./home.module.css";
import {
  Comp1,
  Comp2,
  Comp3,
  Comp4,
  Comp5,
  Comp6,
  HeroSavag,
  HeroImage,
} from "../../Assets";
import CarouselPage from "../Utils/carousel";
import CalendlyWidget from "./CalendlyWidget";

const ScrollText = ({ texts }) => {
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [texts]);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ position: "relative", width: "100%" }}>
        {texts.map((text, index) => (
          <>
            <div
              key={index}
              style={{
                position: "absolute",
                top: `${
                  ((index - visibleIndex + texts.length) % texts.length) * 100
                }px`,
                transition: "top 0.5s ease-in-out",
                color: "#0078D1",
                display: visibleIndex === index ? "block" : "none",
                width: "100%",
              }}
              // className="heading"
            >
              {text}
              <div
                style={{
                  height: "15px",
                  width: "30%",
                  background: "rgba(6, 102, 174, 0.20)",
                  position:"relative",
                  bottom:"3vh"
                }}
              ></div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
const HeroSection = () => {
  const textArrays = [
    "Software",
    "Web Application",
    "Mobile Application",
    "Automation",
  ];
  return (
    <div className={Styles.heroBody} id="hero">
      <div style={{ width: "80%" }}>
        <div
          className="heading"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <span> Building</span>
            <ScrollText texts={textArrays} />
          </div>
          <div> That Drives Company</div>
          <div> Success</div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <link
            href="https://assets.calendly.com/assets/external/widget.css"
            rel="stylesheet"
          />
          {/* <button id="calendly-badge-widget" className={Styles.themeBtn}>
            Get A Free Consult
          </button> */}
          <CalendlyWidget />
          <span className={Styles.heroText}>Trusted by Global Clients</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginTop: "3%",
              alignItems: "center",
              flexWrap: "wrap",
              width:"80%"
            }}
          >
            {[Comp1, Comp2, Comp4, Comp5, Comp3].map((res, index) => (
              <img
                style={{
                  height: index !== 4 ? "40px" : "20px",
                  borderRadius: "10px",
                  objectFit: "contain",
                  // background:"black",
                  padding: "10px",
                }}
                src={res}
                alt="Trusted companies"
              />
            ))}
          </div>
        </div>
      </div>
      <div style={{ width: "20%" }}>
        <div
          style={{
            zIndex: "1",
            position: "absolute",
            right: "2%",
            top: "27%",
            width: "40%",
          }}
        ></div>
        <div
          style={{
            background: "white",
            width: "450px",
            height: "285px",
            position: "absolute",
            top: "31%",
            right: "110px",
            zIndex: "9",
            borderRadius: "10px",
          }}
        >
          {/* <img src={HeroImage1}style={{objectFit:"cover",height:"100%",width:"100%"}} alt="HeroImage1"/> */}
          <CarouselPage />
        </div>
        <img
          src={HeroImage}
          style={{ zIndex: "0", position: "absolute", right: 0, top: "30%" }}
          alt="savag"
        />
      </div>
    </div>
  );
};

export default HeroSection;
